.profile_user_image,
.profile_actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
}
.profile_user_image img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  object-fit: cover;
}
.profile_settings {
  width: 100%;
}
.profile_setting_form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 3% 0;
  column-gap: 1.3rem;
}
.form_input_setting {
  width: 100% !important;
}
.profile_setting_form_elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 47%;
  gap: 1rem;
}
.profile_setting_form_elements_long {
  width: 100%;
}

.user-dropdwon::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
@media (max-width: 744px) {
  .user-dropdwon {
    width: 90vw !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
  }
  .ant-popover-content {
    position: fixed !important;

    top: 60px;
    right: 0;
    z-index: 1000;
  }
}
.profile_setting_form_action {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 3% 0;
}
.profile_setting_form_elements_company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
}
